import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHero from "../../page_components/common/CommonHero";
import {faAnchorCircleCheck, faBook, faChartLine, faDollar, faEnvelope, faExchangeAlt, faKey, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const UserManagement = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHero title={"BitSpreader Documentation"}/>

                <section className="hg_section pt-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/docs/accountmanagement" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faUsers} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Account Management
                                        </h3>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/docs/messaging" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faEnvelope} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Messages and Notifications
                                        </h3>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/docs/apikeys" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faKey} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Exchange & API Key setup
                                        </h3>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="row pt-30">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/docs/balances" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faDollar} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Balances
                                        </h3>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/docs/fst" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faChartLine} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Futures Spread Trading Terminal
                                        </h3>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/docs/trading" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faExchangeAlt} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Basic trading flow
                                        </h3>
                                    </a>
                                </div>
                            </div>


                        </div>
                        <div className="row pt-30">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/docs/positionmanagement" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faAnchorCircleCheck} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Position and exposure management
                                        </h3>
                                    </a>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="hg_section pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="kl-title-block clearfix tbk--text-default tbk--center text-center tbk-symbol--border2 tbk-icon-pos--after-title">
                                    <h3 className="tbk__title fs-m tcolor">
                                        <span className="tbk__border-helper fw-extrabold">
									Videos
								</span>
                                    </h3>

                                    <div className="tbk__symbol ">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-4 mb-sm-20">
                                <div className="box image-boxes imgboxes_style1">
                                    <iframe width="100%" src="https://www.youtube.com/embed/69EWOoxG38A" title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 mb-sm-20">
                                <div className="box image-boxes imgboxes_style1">
                                    <iframe width="100%" src="https://www.youtube.com/embed/B-L822UzBnc" title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 mb-sm-20">
                                <div className="box image-boxes imgboxes_style1">
                                    <iframe width="100%" src="https://www.youtube.com/embed/mqJBBGe7rhc" title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>

                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-4 mb-sm-20">
                                <div className="box image-boxes imgboxes_style1">
                                    <iframe width="100%" src="https://www.youtube.com/embed/z6zFjs05cVc" title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hg_section pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="kl-title-block clearfix tbk--text-default tbk--center text-center tbk-symbol--border2 tbk-icon-pos--after-title">
                                    <h3 className="tbk__title fs-m tcolor">
                                        <span className="tbk__border-helper fw-extrabold">
									Articles
								</span>
                                    </h3>

                                    <div className="tbk__symbol ">
                                        <span></span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="hover-box-2">
                                    <a href="/futuresspreadtrading" target="_self" style={{backgroundColor: "#34495e"}} className="hover-box hover-box-2">
                                        <span className="hb-circle"></span>
                                        <FontAwesomeIcon icon={faBook} size="4x" className="hb-img rb-right"/>
                                        <h3>
                                            Introduction to futures spread trading
                                        </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default UserManagement;
